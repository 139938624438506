<template>
  <div class="cont-box">
    <img src="../../assets/c-bg-top.png">
    <img src="../../assets/c-bg-bottom.png" class="img01">
    <div class="cont">
      <div class="header">
        <img class="logo" src="../../assets/c-logo.png">
        <p class="title">来访信息</p>
      </div>
      <el-form label-width="74px" :rules="rules" ref="subData" :model="subData">
        <el-form-item label="姓名:" prop="username" label-width="46px">
          <el-input v-model="subData.username" readonly></el-input>
        </el-form-item>
        <el-form-item label="性别:" prop="gender" label-width="46px">
          <el-select v-model="subData.gender" disabled>
            <el-option v-for="item in sexArr" :key="item.value" :value="item.value" :label="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item label="身份证号:" prop="idCard">
          <el-input v-model="subData.idCard" readonly></el-input>
        </el-form-item>
        <el-form-item label="联系电话:" prop="phone">
          <el-input v-model="subData.phone" readonly></el-input>
        </el-form-item>
        <el-form-item label="来访人数:" prop="visitorNum">
          <el-input v-model="subData.visitorNum" readonly></el-input>
        </el-form-item>
        <el-form-item label="来访事由:" prop="reason">
          <el-input v-model="subData.reason" readonly></el-input>
        </el-form-item>
        <el-form-item label="来访时间起:" prop="visitTimeStart" label-width="100px">
          <el-date-picker @focus="datePicker" v-model="subData.visitTimeStart" value-format="yyyy-MM-dd HH:mm:ss"
            align="right" type="datetime" placeholder="选择来访时间起">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="来访时间止:" prop="visitTimeEnd" label-width="100px">
          <el-date-picker @focus="datePicker" v-model="subData.visitTimeEnd" value-format="yyyy-MM-dd HH:mm:ss"
            align="right" type="datetime" placeholder="选择来访时间止">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注:" prop="remark" label-width="46px">
          <el-input v-model="subData.remark" readonly></el-input>
        </el-form-item>
        <el-form-item label="被访者:" prop="interviewee" label-width="60px">
          <el-input v-model="subData.interviewee" readonly></el-input>
        </el-form-item>
        <el-form-item label="被访者所在公司:" prop="company" label-width="116px">
          <el-select v-model="subData.company" disabled>
            <el-option v-for="item in gsArr" :key="item.value" :value="item.value" :label="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item label-width="0" class="btn" v-if="subData.status === '-1'">
          <el-button type="primary" @click="submitForm('subData', 'refuse')" plain class="sub-btn refuse">拒&nbsp;&nbsp;绝
          </el-button>
          <el-button type="primary" @click="submitForm('subData', 'pass')" class="sub-btn">同&nbsp;&nbsp;意</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        id: null,
        axios: axios,
        sexArr: [{
            name: '男',
            value: '1'
          },
          {
            name: '女',
            value: '2'
          },
          {
            name: '保密',
            value: '0'
          }
        ],
        gsArr: [{
            name: '科大智能电气',
            value: 'DQ'
          },
          {
            name: '科大智能科技',
            value: 'KJ'
          },
          {
            name: '科大智能机器人',
            value: 'JQR'
          },
          {
            name: '聚川电子',
            value: 'JC'
          },
          {
            name: '智能物流',
            value: 'WL'
          },
          {
            name: '其它',
            value: 'QT'
          }
        ],
        subData: {
          visitTimeStart: '',
          visitTimeEnd: ''
        },
        rules: {
          visitTimeEnd: [{
              type: 'string',
              required: true,
              message: '请选择来访时间止',
              trigger: ['blur', 'change']
            },
            {
              min: 15,
              message: '请选择具体时间',
            },
            {
              validator: (rule, value, callback) => {
                if (new Date(value) < new Date(this.subData.visitTimeStart)) {
                  callback(new Error('来访时间止需大于来访时间始'))
                } else {
                  callback()
                }
              },
              trigger: ['blur', 'change']
            }
          ],
          visitTimeStart: [{
              type: 'string',
              required: true,
              message: '请选择来访时间起',
              trigger: ['blur', 'change']
            },
            {
              min: 15,
              message: '请选择具体时间',
            }
          ]
        }
      }
    },
    mounted() {
      this.id = this.$route.query.id
      this.getList(this.id)

    },
    methods: {
      datePicker() {
        document.activeElement.blur()
      },
      getList(id) {
        this.$axios.post('/visitor-record/findListById', {
          idString: id
        }).then(res => {
          if(res.data.data) {
            this.subData = res.data.data
          }
        }).catch(err => {

        })
      },
      submitForm(formName, type) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (type === 'refuse') {
              this.$alert('确定拒绝来访！', '', {
                confirmButtonText: '确定',
                customClass: 'my-alert',
                center: true,
              }).then(() => {
                this.$axios.post('/visitor-record/save', {
                  id: this.subData.id,
                  status: '2'
                }).then(res => {
                  this.getList(this.id)
                  this.$message({
                    message: '操作成功',
                    type: 'success',
                    center: true,
                    customClass: 'my-mes',
                    duration: 1000
                  })
                }).catch(err => {

                })
              }).catch(() => {

              })

            } else {
              this.$alert('确定同意来访！', '', {
                confirmButtonText: '确定',
                customClass: 'my-alert',
                center: true,
              }).then(() => {
                this.$axios.post('/visitor-record/save', {
                  id: this.subData.id,
                  status: '0',
                  phone: this.subData.phone
                }).then(res => {
                  this.getList(this.id)
                  this.$message({
                    message: '操作成功',
                    type: 'success',
                    center: true,
                    customClass: 'my-mes',
                    duration: 1000
                  })
                }).catch(err => {
              
                })
              }).catch(() => {
              
              })
            }

          } else {
            console.log(valid)
            console.log('error submit!!');
            return false
          }
        })
      }
    }
  }
</script>

<style scoped>
  .cont-box {
    position: relative;
    /* height: 100%; */
    padding-bottom: 3rem;
  }

  .cont-box>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  .cont-box>img.img01 {
    bottom: 0;
    top: auto;
  }

  .el-form {
    padding: 0 2rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .logo {
    width: 40%;
    padding: 1.5rem 0 1rem;
  }

  .title {
    font-size: 1.6rem;
    margin-bottom: 2rem;
    font-weight: bold;
    text-align: center;
    width: 60%;
  }

  .title-d {
    color: #e8380d;
    padding: 0.8rem 0;
  }

  .el-form--label-top>>>.el-form-item__label {
    padding: 0;
    font-weight: bold;
  }

  .el-button {
    width: 100%;
  }

  .el-button.sub-btn {
    margin: 1rem 1rem 0;
    background-color: #e8320d;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1rem 0;
  }

  .el-button.sub-btn.refuse {
    background-color: white;
    border: 1px solid #e8320d;
    color: #e8320d;
  }

  .el-button.pop-btn {
    width: 6rem;
    background-color: #e8320d;
    border: none;
    margin: 1rem 0 .5rem;
  }

  .pop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pop-cont {
    box-sizing: border-box;
    width: 72%;
    height: 16rem;
    background: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 1rem;
  }

  .pop-cont>p:nth-of-type(1) {
    font-size: 1.2rem;
    font-weight: bold;
    margin: .5rem 0 1rem;
  }

  .pop-cont>p:nth-of-type(2) {
    color: #e8320d;
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: .5rem;
  }

  .pop-cont>p:nth-of-type(3) {
    color: #646464;
    text-align: center;
    line-height: 2rem;
  }

  .der {
    padding: 0 2rem 2rem;
  }

  .der>p {
    line-height: 1.8rem;
    color: #e8320d;
  }

  .der>p>span:nth-of-type(1) {
    font-weight: bold;
  }

  .der>p:nth-of-type(2) {
    margin-top: .5rem;
    font-size: .8rem;
    color: #646464;
    line-height: 1.2rem;
  }

  .my-upload>>>.el-upload {
    width: 100%;
  }

  .el-select>>>.el-input__inner,
  .el-input>>>.el-input__inner,
  .el-textarea>>>.el-textarea__inner {
    color: #323232;
  }

  .btn>>>.el-form-item__content {
    display: flex;
  }

  .el-input,
  .el-select {
    width: 100%;
  }
  
  .el-select >>> .el-input .el-input__inner{
    background-color: white !important;
  }
</style>
